import { cloneDeep, get } from 'lodash';
import jsonPath from 'jsonpath';
import jiff from 'jiff';

export class FormUtils {

  static setReadOnlyProperties(form) {
    const modifiedSchema = cloneDeep(form.renderSchema);
    jsonPath.apply(
      cloneDeep(modifiedSchema),
      '$..properties',
      Object.keys
    )
      .forEach(item => {
        const basePath = item.path.filter(pathElement => pathElement !== "$");
        item.value.forEach(propertyName => {
          const property = get(modifiedSchema, [...basePath, 'properties', propertyName]);
          property.readOnly = true;
          if (property['widget'] === 'Select') {
            property['widget'] = 'Text';
          }
        });
      })
    ;
    form.renderSchema = modifiedSchema;
  }

  static applyUISchema(form) {
    // noinspection JSCheckFunctionSignatures
    return jiff.patch(form?.uiSchema?.patch, form.jsonSchema);
  }

}
