import React, { useEffect, useState } from "react";
import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  withStyles
} from '@material-ui/core';
import styles from './document.uploader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFontAwesomeIconFromMimeType } from './getFontAwesomeIconFromMimeType';
import { TransTitle } from '@ui-schema/ui-schema';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const DEBUG = false;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '12pt',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(2),
  },
  padded: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    paddingTop: theme.spacing(1),
  },
  formControl: {
    minWidth: 420,
    margin: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    marginLeft: 0,
  },
  confidentiality: {
    backgroundColor: 'yellow',
    color: '#C00000',
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'yellow',
    color: '#C00000',
    padding: theme.typography.pxToRem(12),
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #C00000',
  },
}))(Tooltip);

export function Base64File({
  value,
  ownKey,
  storeKeys,
  onChange,
  required,
  schema,
}) {
  const fileDetails = value ? JSON.parse(value): null;
  const embedIntoReferral = !!getViewAttribute(schema, 'embedIntoReferral');
  printFileDetails(fileDetails, 'fileDetails');
  const classCodes = getCatalog(schema, 'classCodes');
  const typeCodes = getCatalog(schema, 'typeCodes');
  const healthcareFacilityTypeCodes = getCatalog(schema, 'healthcareFacilityTypeCodes', ownKey);
  const practiceSettingCodes = getCatalog(schema, 'practiceSettingCodes');
  const [fileName, setFileName] = useState(fileDetails?.fileName || '');
  const [title, setTitle] = useState(fileDetails?.title || '');
  const [mimeType, setMimeType] = useState(fileDetails?.mimeType || '');
  const [base64Contents, setBase64Contents] = useState(fileDetails?.base64Contents || '');
  const [description, setDescription] = useState(fileDetails?.description || '');
  const [classCode, setClassCode] = useState(fileDetails?.classCode || classCodes.defaultId);
  const [typeCode, setTypeCode] = useState(fileDetails?.typeCode || typeCodes.defaultId);
  const [healthcareFacilityTypeCode, setHealthcareFacilityTypeCode] = useState(fileDetails?.healthcareFacilityTypeCode || healthcareFacilityTypeCodes.defaultId);
  const [practiceSettingCode, setPracticeSettingCode] = useState(fileDetails?.practiceSettingCode || practiceSettingCodes.defaultId);
  const [confidentialityRestricted, setConfidentialityRestricted] = useState(fileDetails?.confidentialityRestricted || false);
  const classes = useStyles();

  useEffect(
    () => {
      const newValue = isAnyStringEmpty(fileName, title, base64Contents)
        ? {}
        : {
          fileName,
          title,
          mimeType,
          base64Contents,
          description: description || undefined,
          classCode: classCode || undefined,
          typeCode: typeCode || undefined,
          healthcareFacilityTypeCode: healthcareFacilityTypeCode || undefined,
          practiceSettingCode: practiceSettingCode || undefined,
          confidentialityRestricted,
          embedIntoReferral,
        }
      onChange(
        storeKeys,
        ['value'],
        () => ({ value: newValue ? JSON.stringify(newValue) : null }),
        schema.get('deleteOnEmpty') || required,
        schema.get('type'),
      );
      printFileDetails(newValue, 'newValue');
    },
    [
      fileName,
      title,
      mimeType,
      base64Contents,
      description,
      classCode,
      typeCode,
      healthcareFacilityTypeCode,
      practiceSettingCode,
      confidentialityRestricted,
      embedIntoReferral,
      onChange,
      required,
      schema,
      storeKeys,
    ]
  );

  const upload = async (selectedFile) => {
    if (!selectedFile) {
      return;
    }
    try {
      await updateFileDetails(selectedFile);
    }
    catch (error) {
      return error.message;
    }
  };

  const getSelectedFile = (files) => (
    files && files.length > 0 ? files[0] : { name: null }
  );

  const selectFile = async (event) => {
    const selectedFile = getSelectedFile(event.target.files);
    await upload(selectedFile);
  };

  const updateFileDetails = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const dataUrlElements = reader.result.match(/^data:(.+);base64,(.+)$/);
        setFileName(file.name);
        setTitle(getTitleFromFilename(file.name));
        setMimeType(dataUrlElements[1]);
        setBase64Contents(dataUrlElements[2]);
        resolve();
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const handleClassCodeChange = (event) => {
    setClassCode(event.target.value);
  }

  const handleTypeCodeChange = (event) => {
    setTypeCode(event.target.value);
  }

  const handlePracticeSettingCodeChange = (event) => {
    setPracticeSettingCode(event.target.value);
  }

  const handleHealthcareFacilityTypeCodeChange = (event) => {
    setHealthcareFacilityTypeCode(event.target.value);
  }

  const handleConfidentialityRestrictedChange = (event) => {
    setConfidentialityRestricted(event.target.checked);
  }

  const icon = getFontAwesomeIconFromMimeType(mimeType);

  const ErrorWrapper = ({ children, error }) => (
    <span style={{
      color: error ? 'red': 'rgba(0, 0, 0, 0.87)'
    }}>
      {children}
    </span>
  )

  const CatalogValue = ({ catalog, label, value, onChange }) => {
    if (embedIntoReferral || catalog.isSingle) {
      return null;
    }
    return (
      <Grid item>
        <FormControl className={classes.formControl} error={required && catalog.isEmpty}>
          <InputLabel>{label}</InputLabel>
          <Select
            value={value}
            required
            disabled={catalog.isSingle || catalog.isEmpty}
            onChange={onChange}
          >
            { getMenuItems(catalog) }
          </Select>
          {
            required && catalog.isEmpty &&
            <FormHelperText>{label} is required.</FormHelperText>
          }
        </FormControl>
      </Grid>
    )
  };

  return (
    <Paper className={classes.padded} variant="outlined">
      <div className={classes.title}>
        <TransTitle schema={schema} storeKeys={storeKeys} ownKey={ownKey} />
        {
          required && isEmptyString(base64Contents) &&
          <div>
            <FormControl error={required && isEmptyString(base64Contents)}>
              <FormHelperText>File is required.</FormHelperText>
            </FormControl>
          </div>
        }
      </div>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <label>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={selectFile}
                    />
                    <Button className={styles.btnChoose} variant="outlined" component="span">
                      <ErrorWrapper error={required && isEmptyString(base64Contents)}>
                        Choose File
                      </ErrorWrapper>
                    </Button>
                  </label>
                </Grid>
                {
                  base64Contents &&
                  <Grid item>
                    {
                      icon &&
                      <FontAwesomeIcon
                        icon={icon}
                        size="2x"
                        style={{
                          color: '#006181',
                        }}
                      />
                    }
                  </Grid>
                }
                <Grid item>
                  <div style={{
                    padding: '5px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    width: base64Contents ? '220px' : '260px',
                    height: '22px',
                    border: '1px solid #C4C4C4',
                    borderRadius: '5px',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#E5E5E8',
                    color: '#006181',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    <ErrorWrapper error={required && isEmptyString(fileName)}>
                      { fileName || '<File Name>' }
                    </ErrorWrapper>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl} error={required && isEmptyString(title)}>
                <TextField
                  label="Document Title"
                  fullWidth
                  required
                  value={title}
                  onChange={handleTitleChange}
                  variant="outlined"
                  size="small"
                />
                {
                  required && isEmptyString(title) &&
                  <FormHelperText>Title is required.</FormHelperText>
                }
              </FormControl>
            </Grid>
            <CatalogValue
              catalog={classCodes}
              label="Document Class"
              value={classCode}
              onChange={handleClassCodeChange}
            />
            <CatalogValue
              catalog={healthcareFacilityTypeCodes}
              label="Facility Type"
              value={healthcareFacilityTypeCode}
              onChange={handleHealthcareFacilityTypeCodeChange}
            />
            <Grid item>
              <HtmlTooltip
                arrow
                title={
                  <>
                    Confidentiality signifies sensitive health information that may include
                    but not limited to:
                    <ul>
                      <li>
                        <p>Drug, alcohol, or substance abuse</p>
                      </li>
                      <li>
                        Psychological, psychiatric, or other mental impairment(s)
                        or developmental disabilities (excludes “psychotherapy notes”
                        as defined in HIPAA at 45 CFR 164.501)
                      </li>
                    </ul>
                    This information will not be shared with any care team members
                    unless there is explicit consent from the patient.
                  </>
                }
              >
                <FormControlLabel
                  control={
                    <Switch
                      required
                      checked={confidentialityRestricted}
                      onChange={handleConfidentialityRestrictedChange}
                    />
                  }
                  label="Confidential"
                />
              </HtmlTooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item>
              <TextField
                label="Document Description"
                fullWidth
                multiline
                rows={4}
                value={description}
                onChange={handleDescriptionChange}
                variant="outlined"
                size="small"
              />
            </Grid>
            <CatalogValue
              catalog={typeCodes}
              label="Document Type"
              value={typeCode}
              onChange={handleTypeCodeChange}
            />
            <CatalogValue
              catalog={practiceSettingCodes}
              label="Practice Setting"
              value={practiceSettingCode}
              onChange={handlePracticeSettingCodeChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

function isEmptyString(value) {
  return _.chain(value).trim().value() === '';
}

function isAnyStringEmpty(...values) {
  for (let value of values) {
    if (isEmptyString(value)) {
      return true;
    }
  }
  return false;
}

function getTitleFromFilename(fileName) {
  let title = String(fileName);
  const lastDotPos = title?.lastIndexOf('.');
  if (lastDotPos > 0) {
    title = title.substring(0, lastDotPos);
  }
  const numberedNameParts = /([0-9]+)(.+)/.exec(title);
  if (numberedNameParts?.length === 3) {
    title = numberedNameParts[2];
  }
  title = _.chain(title).trim().startCase().value() || '';
  return title;
}

function getViewAttribute(schema, attributeName) {
  const attributeValue = schema?.get('view')?.get(attributeName);
  return attributeValue?.toJS ? attributeValue.toJS() : attributeValue;
}

function getCatalog (schema, catalogName) {
  const catalog = getViewAttribute(schema, catalogName);
  const items = (catalog?.items)
    ? _.sortBy(catalog.items, 'value')
    : []
  ;
  const defaultId = isEmptyString(catalog?.defaultId)
    ? (items.length > 0 ? items[0].id : null)
    : catalog.defaultId
  ;
  return (
    catalog
      ? {
        defaultId,
        items,
        isEmpty: items.length === 0,
        isSingle: items.length === 1,
      }
      : {
        defaultId: null,
        items: [],
        isSingle: false,
        isEmpty: true,
      }
  )
}

let fileDetailsPrinted = 0;

function getMenuItems (catalog) {
  return (catalog?.items || []).map(catalogItem =>
    <MenuItem key={catalogItem.id} value={catalogItem.id}>{catalogItem.value}</MenuItem>
  )
}

function printFileDetails(fileDetails, label) {
  if (!DEBUG) {
    return;
  }
  console.log(
    [
      `---------------------------------`,
      String(++fileDetailsPrinted).padStart(3, '0'),
      `---------------------------------\n`,
    ]
      .join(' ')
    +
    (label ? `${label}: ` : '') +
    (
      !fileDetails
        ? fileDetails
        : JSON.stringify(
          {
            ...fileDetails,
            ...(
              fileDetails?.base64Contents
                ? { base64Contents: fileDetails?.base64Contents?.substring(0, 50) + '...' }
                : {}
            )
          },
          null,
          2
        )
    )
  );
}
