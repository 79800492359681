import Alert from '@material-ui/lab/Alert';
import React from 'react';

export const Message = ({
  severity,
  center,
  children,
}) => (
  <Alert
    severity={severity}
    icon={!!center ? false : undefined}
    style={{
      marginTop: 24, ...(center ? {
        display: 'inline-block',
        margin: 'auto'
      } : {})
    }}
  >
    {children}
  </Alert>
);
