import {
  isPlainObject,
  isString,
  isEmpty,
  isNumber,
  isBoolean
} from 'lodash';

export class ObjectUtils {
  static expand(obj) {
    return expand(obj);
  }
}

function expand(obj) {
  if (isEmptyLike(obj)) {
    return undefined;
  }
  if (isString(obj)) {
    try {
      return JSON.parse(obj);
    }
    catch (error) {
      return obj;
    }
  }
  if (Array.isArray(obj)) {
    return obj.reduce(
      (expanded, item, index) => {
        expanded[index] = expand(item);
        return expanded;
      },
      []
    );
  }
  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (expanded, key) => {
        expanded[key] = expand(obj[key]);
        return expanded;
      },
      {}
    );
  }
  return obj;
}

function isEmptyLike(obj) {
  return (
      !isNumber(obj)
      && !isBoolean(obj)
      && isEmpty(obj)
    )
    || obj === 'null'
    || obj === 'undefined'
    ;
}
