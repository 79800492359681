import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import Loading from "@material-ui/icons/Autorenew";
import LoadError from "@material-ui/icons/Error";
import { createEmptyStore, createOrderedMap, createStore, storeUpdater, UIGenerator } from "@ui-schema/ui-schema";
import { browserT } from "./utils/translations";
import { Step, Stepper, widgets } from "@ui-schema/ds-material";
import { RichText, RichTextInline } from "@ui-schema/material-richtext";
import { FormManagementAPI } from "../api/FormManagementAPI";
import { Message } from "./Message";
import { FormRepository } from "../domain/repositories/FormRepository";
import { File } from "./File";
import { Base64File } from "./Base64File";
import { ObjectUtils } from "../utils/ObjectUtils";
import Ajv from "ajv";
import addFormats from "ajv-formats";

const { cloneDeep } = require("lodash");

const ajv = new Ajv({ allErrors: true, strict: false });
addFormats(ajv);

const customWidgets = { ...widgets };
customWidgets.custom = {
  ...widgets.custom,
  RichText: RichText,
  RichTextInline: RichTextInline,
  Stepper: Stepper,
  Step: Step,
  File,
  Base64File: Base64File,
};

const FormRenderer = ({ formUUID, formParameters, submitError }) => {

  const [showValidity, setShowValidity] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [schema, setSchema] = useState(createOrderedMap({}));
  const [store, setStore] = useState(() => createEmptyStore());
  const [form, setForm] = useState(null);
  const isDataValid = useRef(() => true);

  const dataIsInvalid = () => {
    const storeValues = store.valuesToJS();
    const isValid = isDataValid.current(storeValues);
    if (!isValid) {
      console.log(
        {
          uiSchema: form.renderSchema,
          storeValues,
          validationErrors: isDataValid.current.errors,
        },
      );
    } else {
      console.log({ storeValues });
    }
    return !isValid;
  };

  const toggleError = (error) => {
    setSuccessMessage("");
    setError(error);
  };

  const toggleSuccessMessage = (message) => {
    setError(null);
    setSuccessMessage(message);
  };

  useEffect(
    () => {
      if (formUUID) {
        FormRepository.getParametrizedForm(formUUID, formParameters)
          .then(form => {
            isDataValid.current = ajv.compile(normalizeSchema(form.renderSchema));
            setForm(form);
            setSchema(createOrderedMap(form.renderSchema));
            if (form.response) {
              setStore(createStore(createOrderedMap(form.response.contents)));
            }
            window.parent.postMessage({
              type: "form-loaded",
              payload: true,
            }, "*");
          })
          .catch(error => toggleError(error))
        ;
      } else {
        toggleError(new Error("Form UUID was not provided."));
      }
    },
    [formUUID, formParameters],
  );

  const _dataIsInvalid = dataIsInvalid();
  useEffect(
    () => {
      setError(null);
    },
    [_dataIsInvalid],
  );

  const onChange = useCallback(
    (storeKeys, scopes, updater, deleteOnEmpty, type) => {
      setStore(storeUpdater(storeKeys, scopes, updater, deleteOnEmpty, type));
    },
    [setStore],
  );

  const handleSubmit = async () => {
    try {
      const isValid = !dataIsInvalid();
      setShowValidity(!isValid);
      const formResponse = store.getValues();
      if (isValid && formResponse) {
        const response = formResponse.toJS();
        const { uuid: responseUUID, url: responseURL } = await FormManagementAPI
          .submitForm(formUUID, response, formParameters)
        ;
        if (formParameters["showSuccessMessage"]) {
          toggleSuccessMessage("Form was submitted successfully.");
        } else {
          toggleError(null);
        }
        window.parent.postMessage({
          type: "form-submitted",
          payload: ObjectUtils.expand({
            uuid: responseUUID,
            url: responseURL,
            ...response,
          }),
        }, "*");
      }
    } catch (error) {
      toggleError(error);
    }
  };

  const ErrorMessage = ({ center }) => {
    const _error = (submitError || error);
    return _error?.errorMessages
      ? (
        <Message severity="error" center={center}>
          Please correct the following errors:
          <ul>
            {(_error?.errorMessages || []).map(message => <li>{message}</li>)}
          </ul>
        </Message>
      )
      : <Message severity="error" center={center}>{_error?.message}</Message>
      ;
  };

  const SuccessMessage = ({ center }) => {
    return <Message severity="success" center={center}>{successMessage}</Message>;
  };

  if (schema.size === 0) {
    return <div
      style={{
        textAlign: "center",
        margin: "75px 0",
      }}
    >
      {
        error && (
          <>
            <LoadError
              fontSize={"large"}
              style={{ color: "red" }}
            />
            <p style={{ color: "red" }}>Unable to load Form</p>
            <ErrorMessage center/>
          </>
        )
      }
      {
        !error && (
          <>
            <Loading
              className={"refresh-spin"}
              fontSize={"large"}
            />
            <p>Loading Form</p>
          </>
        )
      }
    </div>;
  }

  return (
    <React.Fragment>
      <UIGenerator
        schema={schema}
        store={store}
        onChange={onChange}
        widgets={customWidgets}
        showValidity={showValidity}
        t={browserT}
      />
      {
        !dataIsInvalid() &&
        <Button
          style={{ marginTop: 24 }}
          onClick={handleSubmit}
          variant={"contained"}
        >
          Submit Form
        </Button>
      }
      {
        (submitError || error) && <ErrorMessage/>
      }
      {
        successMessage && <SuccessMessage/>
      }
      {
        !form.canResubmit() && <Message severity="warning">
          The form has already been submitted.
        </Message>
      }
    </React.Fragment>
  );
};

// noinspection DuplicatedCode
/**
 * This function normalizes the display schema to make multi-select elements compliant with the JSON Schema
 * specification so they can be validated by the <code>ajv</code> library.
 *
 * It recursively finds subschemas of type <code>array</code> which specify the allowed options in an
 * <code>enum</code> property (which is the structure required by the <code>ui-schema</code> library)
 * and moves the <code>enum</code> property inside an <code>items</code> property along with a <code>type</code>
 * property specifying the element type (e.g., string or number).
 *
 * @param uiSchema - the display schema
 * @returns the normalized schema.
 */
function normalizeSchema(uiSchema) {
  const validationSchema = cloneDeep(uiSchema);
  const normalizeRecursive = (schema) => {
    if (schema.type === "object") {
      for (const subschema of Object.values(schema.properties)) {
        normalizeRecursive(subschema);
      }
    } else if (schema.type === "array") {
      if (Array.isArray(schema.enum) && schema.enum.length > 0) {
        const itemType = typeof schema.enum[0];
        if (schema.items) {
          normalizeRecursive(schema.items);
        } else {
          schema.items = {
            type: itemType,
            enum: schema.enum,
          };
          delete schema.enum;
        }
      }
    }
  };
  normalizeRecursive(validationSchema);
  return validationSchema;
}

export default FormRenderer;
