import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import { Paper } from "@material-ui/core";
import FormRenderer from "./components/FormRenderer";
import { UrlUtils } from './utils/UrlUtils';
import buildInfo from './build.json';

const FORM_UUID_PARAMETER = 'formUUID';

function MainPage() {

  const [ formParameters, setFormParameters ] = useState(null);
  const [ submitError, setSubmitError ] = useState(null);

  const messageListener = useRef(
    async (event) => {
      const formEvent = event.data;
      if (formEvent.type === 'parameters-sent') {
        setFormParameters(formEvent.payload);
      }
      else if (formEvent.type === 'submit-error') {
        setSubmitError(formEvent.payload);
      }
    }
  );

  useEffect(
    () => {
      const listener = messageListener.current;
      window.addEventListener('message', listener);
      window.parent.postMessage({
        type: 'renderer-ready',
        payload: true
      }, '*');
      return () => {
        window.removeEventListener('message', listener);
      }
    },
    []
  );

  if (!formParameters) {
    const cellStyle = {
      border: '1px solid black ',
      padding: '10px',
      textAlign: 'right'
    };
    return (
      <div style={{ padding: '10px' }}>
        <h1>FormRenderer Module</h1>
        <table style={{ borderCollapse: 'collapse', margin: '10px' }}>
          <tbody>
            <tr>
              <td style={cellStyle}>
                Build Hash:
              </td>
              <td style={cellStyle}>
                <code>
                  {buildInfo.codeHash}
                </code>
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>
                Hash Timestamp:
              </td>
              <td style={cellStyle}>
                <code>
                  {buildInfo.hashTimestamp}
                </code>
              </td>
            </tr>
            </tbody>
        </table>
      </div>
    );
  }
  return (
    <Paper style={{ margin: 12, padding: 24 }}>
      <FormRenderer
        formUUID={UrlUtils.getSearchParameter(FORM_UUID_PARAMETER)}
        formParameters={formParameters}
        submitError={submitError}
      />
    </Paper>
  );
}

function App() {
  return <MainPage/>;
}

export default App;
