import { isEmpty } from 'lodash';

export class Form {

  jsonSchema;
  uiSchema;
  renderSchema;
  response;
  resubmitAllowed;

  constructor({ jsonSchema, uiSchema, renderSchema, resubmitAllowed, response } = {}) {
    Object.assign(this, arguments[0]);
  }

  canResubmit = () => {
    return isEmpty(this.response) || (!isEmpty(this.response) && this.resubmitAllowed);
  }

}
