import { keyBy } from 'lodash';
const ENV = process['env'];

const CONFIG_ITEMS = keyBy(
  [
    {
      name: 'maxBinaryFileSizeInKB',
      varName: 'REACT_APP_ATTACHMENT_MAX_BINARY_FILE_SIZE_KB',
      type: 'number',
      required: true,
    },
    {
      name: 'formApiRoot',
      varName: 'REACT_APP_FORM_API_ROOT',
      type: 'string',
      required: true,
    },
  ],
  'name'
);

export class Config {

  static getFormApiRoot() {
    return getValue('formApiRoot');
  }

  static getMaxBinaryFileSizeInKb() {
    return getValue('maxBinaryFileSizeInKB');
  }
}

const CONVERTERS_BY_TYPE = {
  'number': (configItem, value) => {
    const numberValue = parseFloat(value.trim());
    if (!Number.isFinite(numberValue)) {
      throw new Error(`'${configItem.varName}' environment variable must have a numeric value.`);
    }
    return Number(numberValue.toFixed(2));
  },
  'integer': (configItem, value) => {
    const intValue = parseInt(value.trim(), 10);
    if (!Number.isFinite(intValue)) {
      throw new Error(`'${configItem.varName}' environment variable must have an integer value.`);
    }
    return intValue;
  },
  'string': (configItem, value) => value.trim(),
};

function getValue(name) {
  const configItem = CONFIG_ITEMS[name];
  if (configItem.value) {
    return configItem.value;
  }
  const value = ENV[configItem.varName];
  if (!value && configItem.required) {
    throw new Error(`Required '${name}' environment variable was not set.`);
  }
  configItem.value = CONVERTERS_BY_TYPE[configItem.type](configItem, value);
  return configItem.value;
}
