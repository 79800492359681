import React, { useState } from 'react';
import { applyPluginStack, TransTitle, useUI } from '@ui-schema/ui-schema';
import { StringRenderer, ValidityHelperText } from '@ui-schema/ds-material';
import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get, startCase } from 'lodash';
//import { Config } from '../utils/config';

const EMPTY_FILE = { name: null };

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      padding: theme.spacing(2),
    },
  },
}));

const WidgetTextField = applyPluginStack(StringRenderer)

export const File = ({
  ownKey, storeKeys, onChange, required, schema, level, errors, showValidity
}) => {
  const { store } = useUI();
  const file = get(store?.valuesToJS(), ownKey);
  const readOnly = schema.get('readOnly');
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(EMPTY_FILE);

  const selectFile = async (event) => {
    event.persist();
    const file = get(event, 'target.files[0]', EMPTY_FILE);
    const schemaFile = await toSchemaFile(file);
    onChange(
      storeKeys, ['value'],
      () => ({ value: schemaFile }),
      schema.get('deleteOnEmpty') || required,
      schema.get('type'),
    );
    setSelectedFile(file);
  };

  const newTextField = ({ propertyName }) => (
    <WidgetTextField
      level={level + 1}
      storeKeys={storeKeys.push(propertyName)}
      schema={schema['getIn'](['properties', propertyName])}
      parentSchema={schema}
    />
  );

  return (
    <div className={classes.root}>
      <Paper elevation={0} variant='outlined'>
        <label htmlFor="btn-upload">
          <Typography component='span' style={{ marginRight: '1rem' }}>
            <TransTitle schema={schema} storeKeys={storeKeys} ownKey={ownKey}/>
          </Typography>
          <input
            type="file"
            required={required}
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            onChange={selectFile}
          />
          {
            !readOnly &&
            <Button variant="outlined" component="span">
              Choose File
            </Button>
          }
          <span style={{ marginLeft: '1rem'}}>
            { readOnly ? get(file, 'fileName') : selectedFile.name }
          </span>
        </label>
        <ValidityHelperText
          errors={errors} schema={schema} showValidity={showValidity}
        />
        {
          selectedFile.name &&
          <div style={{ marginTop: '1rem' }}>
            {
              newTextField({ propertyName: 'name' })
            }
            {
              newTextField({ propertyName: 'description' })
            }
          </div>
        }
      </Paper>
    </div>
  );
};

async function toSchemaFile(file) {
  // validateFileSize(file);
  try {
    const dataUrl = await getFileDataAsUrl(file);
    return {
      fileName: file.name,
      name: startCase(getBareFilename(file.name)),
      mimeType: file.type,
      base64Contents: dataUrl.substring(dataUrl.indexOf(',') + 1),
      binarySizeInBytes: file.size,
    }
  }
  catch (error) {
    console.error(error);
    return null;
  }
}

async function getFileDataAsUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
}

function getBareFilename(filename) {
  const lastDotPosition = filename.lastIndexOf('.');
  if (lastDotPosition > 0) {
    return filename.substring(0, lastDotPosition)
  }
  return filename;
}

// function validateFileSize(file) {
//   const maxFileSizeInKb = Config.getMaxBinaryFileSizeInKb();
//   const maxBinaryFileSizeInBytes = maxFileSizeInKb * 1024;
//   if (file.size > maxBinaryFileSizeInBytes) {
//     throw new Error(`Maximum allowed file size is ${maxFileSizeInKb} KB.`);
//   }
// }
