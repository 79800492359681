import axios from 'axios';
import { FormApiError } from './FormApiError';
import { Config } from '../utils/config';

const FORM_API_ROOT = Config.getFormApiRoot();

axios.interceptors.response.use(
  response => response,
  error => {
    const statusCode = error?.response?.data?.statusCode;
    if (error.statusCode !== 404) {
      return Promise.reject(
        new FormApiError(
          error?.response?.data?.message || error?.message,
          statusCode,
          error?.response?.data?.errorMessages
        )
      );
    }
  }
);

export class FormManagementAPI {

  static async getParametrizedForm(formUUID, formParameters) {
    const result = await axios({
      method: 'post',
      url: `${FORM_API_ROOT}/parametrized/${formUUID}`,
      data: formParameters
    });
    return result?.data;
  }

  static async submitForm(formUUID, formResponse, formParameters) {
    const result = await axios({
      method: 'post',
      url: `${FORM_API_ROOT}/response/${formUUID}`,
      data: {
        contents: formResponse,
        parameters: formParameters,
      }
    });
    return result?.data;
  }

}
