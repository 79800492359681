import { createMap, makeTranslator } from '@ui-schema/ui-schema';
import * as en from '@ui-schema/dictionary/en';

const dicEN = createMap({
  error: en.errors,
  labels: { ...en.labels, ...en.richText, ...en.dnd },
  formats: { ...en.formats },
  pagination: { ...en.pagination },
});

const tEN = makeTranslator(dicEN, 'en');

const browserT = (text, context, schema) => {
  return tEN(text, context, schema);
};

export { browserT };
