export class UrlUtils {

  static getSearchParameter(paramName) {
    return new URLSearchParams(window.location.search).get(paramName);
  }

  static getQueryString(excludedParamNames) {
    let paramKVPs = Array.from(new URLSearchParams(window.location.search).entries());
    if (excludedParamNames) {
      paramKVPs = paramKVPs.filter(kvp => !excludedParamNames.includes(kvp[0]));
    }
    return paramKVPs.map(kvp => `${kvp[0]}=${kvp[1]}`).join('&');
  }

}
