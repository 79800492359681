import { FormManagementAPI } from '../../api/FormManagementAPI';
import { Form } from '../Form';
import { FormUtils } from '../utils/FormUtils';

export class FormRepository {

  static async getParametrizedForm(formUUID, formParameters) {
    const jsonForm =
      // getMockForm() ||
      await FormManagementAPI.getParametrizedForm(formUUID, formParameters)
    ;
    const form = new Form(jsonForm);
    form.renderSchema = FormUtils.applyUISchema(form)
    if (!form.canResubmit()) {
      FormUtils.setReadOnlyProperties(form);
    }
    return form;
  }

}

/*
function getMockForm() {
  const mockFormFilename = 'tmpGetMockForm';
  try {
    return require(`./${mockFormFilename}`)?.default?.();
  }
  catch (error) {
    return null;
  }
}
*/
